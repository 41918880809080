import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import logo from '../images/logos/code-championship-logo-blue.svg';
import LoadingSpinner from '../components/loading-spinner';

import './certificate.css';

const CertificatePage = ({ location }) => {
  const [name, setName] = useState('');
  const [coach, setCoach] = useState('');
  const [details, setDetails] = useState('');
  const [isAdmin, setIsAdmin] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [copySuccess, setCopySuccess] = useState('');
  const [shareLink, setShareLink] = useState('');

  useEffect(() => {
    // these are in the useEffect to prevent them from being pre-rendered by Gatsby
    const query = queryString.parse(location.search);
    setName(query.name || '');
    setCoach(query.coach || '');
    setDetails(query.details || '');
    setIsLoading(true);
    setIsAdmin(query.admin);
    if (query.admin) {
      setIsLoading(false);
    } else {
      setTimeout(() => {
        setIsLoading(false);
        if (window && window.print) {
          setTimeout(window.print, 200);
        }
      }, 2000);
    }
  }, []);

  useEffect(() => {
    setCopySuccess('');
    setShareLink(`/certificate/?${queryString.stringify({ name, coach, details })}`);
  }, [name, coach, details]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${process.env.CURRENT_BASE_URL}${shareLink}`);
    setCopySuccess('Copied!');
  };

  return (
    <>
      {isAdmin && (
        <div
          style={{
            zIndex: 1000, background: 'white', position: 'absolute', minWidth: '40vw',
          }}
        >
          <h2>Add Coder Details</h2>
          Name:
          <input
            value={name}
            onChange={(event) => setName(event.target.value)}
            placeholder="Coder's Full Name"
          />
          <br />
          Coach:
          <input
            value={coach}
            onChange={(event) => setCoach(event.target.value)}
            placeholder="Instructor's Full Name"
          />
          <br />
          Details:
          <textarea
            value={details}
            onChange={(event) => setDetails(event.target.value)}
            style={{ width: '90%' }}
            placeholder={name ? `Something personal, like: ${name.split(' ')[0]} has gone above and beyond this season, not only in exceptional computer programming, but even more importantly, by welcoming other coders into a friendly community.` : 'Something fantastic they did!'}
          />
          <br />
          <div>
            {details.length}
            {' / 400'}
            {
              details.length > 400
              && <b className="error-message"> - This text is over 400 characters and may not fit well on the certificate</b>
            }
          </div>
          <div>
            Shareable certificate Link:
            <input value={window.location.href} />
            <button
              type="button"
              className="simple-button"
              onClick={copyToClipboard}
            >
              Copy
            </button>
            {copySuccess}
            <br />
            <small>
              Copy and share this link with your coder.
            </small>
          </div>
        </div>
      )}
      <div className="certificate">
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Herr+Von+Muellerhoff&display=swap" rel="stylesheet" />
        <div
          style={{
            padding: '20px',
            border: '10px solid',
            position: 'absolute',
            overflow: 'hidden',
            left: 30,
            right: 30,
            top: 30,
            bottom: 30,
          }}

        >
          <div style={{
            padding: '20px',
            paddingTop: '30px',
            border: '5px solid',
            position: 'absolute',
            overflow: 'hidden',
            left: 30,
            right: 30,
            top: 30,
            bottom: 30,
          }}
          >
            <center style={{
              fontSize: '50px',
              fontWeight: 'bold',
              fontFamily: 'Orbitron',
            }}
            >
              Code Championship Certificate
            </center>
            <img
              src={logo}
              alt="Code Championship Logo"
              style={{ width: 200 }}
              display="inline-block"
            />
            <hr
              className="stripe-hr"
              style={{
                marginTop: '-50px',
                marginBottom: '0px',
                minHeight: '25px',
                height: '25px',
                transform: 'skewY(-12deg)',
              }}
            />
            <br />
            <br />
            {isLoading ? <LoadingSpinner /> : (
              <center>
                <div style={{ fontSize: '20px' }}>
                  <i>This is to certify that</i>
                </div>
                <br />
                <br />
                <div style={{ fontSize: '40px' }} className="static-underline">
                  <b>
                    &nbsp;&nbsp;&nbsp;
                    {name}
                    &nbsp;&nbsp;&nbsp;
                  </b>
                </div>
                <br />
                <br />
                <div style={{ fontSize: '20px' }}>
                  <i>
                    has shown exceptional programing skill and
                    earned the title of a Code Champion!
                  </i>
                </div>
                <br />
                <br />
                <div style={{ fontSize: '15px' }}>
                  {details}
                </div>
                <br />
                <br />
                <div className="signature" style={{ fontSize: '50px' }}>
                  &nbsp;&nbsp;&nbsp;
                  {coach}
                  &nbsp;&nbsp;&nbsp;
                </div>
                <div>
                  Coach
                </div>
              </center>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

CertificatePage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default CertificatePage;
